<template>
  <div class="container">
    <div class="text-right my-4"></div>
    <div class="text-right"></div>
    <!-- v-data-table  -->

    <!-- <d-player :options2="options2"
              @play="play"
              ref="player">
    </d-player> -->

    <v-row>
      <v-col cols="12">
        <div class="tableWrapper">
          <v-data-table
            :headers="headers"
            :single-select="singleSelect"
            item-key="id"
            v-model="selected"
            :items="mobiledata"
            :options.sync="options"
            :server-items-length="total"
            :loading="isLoading"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:item.entry_date="{ item }">
              {{ formateDate(item.entry_date) }}
            </template>
            <template v-slot:item.comments="{ item }">
              <div
                class="text"
                v-if="item.comments.split(' ').length < 10"
                v-html="item.comments"
              ></div>
              <div class="text" v-else>
                {{ item.comments.slice(0, 100) }}..
                <span
                  style="cursor: pointer; color: blue"
                  @click="opencomment(item.comments)"
                  >Read more
                </span>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-4 pb-4">
            <Pagination
              :isloading="isLoading"
              :startRecord="startRecord"
              :currentPage="currentPage"
              :lastPage="lastPage"
              :lastRecord="lastRecord"
              :totRecords="totRecords"
              :isCurrentPageClass="isCurrentPageClass"
              :perpage="perpage"
              :getLastPageClass="getLastPageClass"
              :totPage="totPage"
              :getPages="getPages"
              @handlePerPage="handlePerPage"
              @paginate="paginate"
              @last="last"
              @getDataByPage="getDataByPage"
              :showPerPage="showPerPage"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="viewcomments" max-width="650">
      <v-card>
        <v-card-title class="text-h3"> Comments </v-card-title>

        <v-card-text v-html="commenthtml"> </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="viewcomments = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import Pagination from "./component/Pagination.vue";
// import videoPlayer from 'vuetify-media-player/src/components/video-player.vue'
// import 'vuetify-media-player/src/style.styl'
// import VueDPlayer from 'vue-dplayer'
// import 'https://unpkg.com/browse/vue-dplayer@0.0.10/dist/vue-dplayer.css'
import MobileDigitalTemp from "./component/MobileDigitalTemp";
export default {
  // components: { Index, Buttons, Loading, Pagination,videoPlayer },
  components: { Index, Buttons, Pagination, MobileDigitalTemp },
  data() {
    return {
      commenthtml: "",
      viewcomments: false,
      section_name: "",
      sector_id: "",
      sectormappingdialog: false,
      sectordata: [{ sectorName: "Insurance", sector_id: "4" }],

      addsectiondialog: false,
      mappingsectiondialog: false,
      toggleUpdateModal: false,
      //////table data starts here////
      selected: [],
      singleSelect: false,

      digital_channel: [
        "All",
        "Online Display",
        "Search Engine Marketing",
        "Online Video",
      ],
      digital_channel_data: "All",
      options: {},
      sort: "",
      total: 0,
      mapped_sectors_data: [],
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "Entry Date",
          align: "start",
          value: "entry_date",
          width: "80px",
          sortable: false,
          class: "v-data-table-header ",
        },
        {
          text: "totalFiles",
          value: "totalFiles",
          sortable: false,
          width: "80px",
          class: "v-data-table-header",
        },
        {
          text: "Total Duplicates",
          align: "start",
          value: "totalduplicatedata",
          width: "80px",
          sortable: false,
          class: "v-data-table-header ",
        },
        {
          text: "Ftp import time",
          align: "start",
          value: "ftpimport",
          width: "80px",
          sortable: false,
          class: "v-data-table-header ",
        },
        {
          text: "Download time",
          align: "start",
          value: "downloadtime",
          width: "80px",
          sortable: false,
          class: "v-data-table-header ",
        },
        {
          text: "Product time",
          align: "start",
          value: "productcreationtime",
          width: "80px",
          sortable: false,
          class: "v-data-table-header ",
        },
      ],

      ////// table data ends here
      valid: true,
      checkbox: false,
      operation: [],
      modules: [],
      mobiledata: [],
      isLoading: true,
      fullPage: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      deleteItems: [],
      checked_Id: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      parent_id: [],
      module_sort: [],
      allSelected: false,
      new: [],
      panel_data: [],
      addModule: {
        moduleName: null,
        parent_id: null,
        module_icon: "mdi-view-dashboard",
        parent_id: null,
        url_slug: null,
        panel_id: null,
      },

      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      urlRules: [(v) => !!v || "Url is required"],
      addModulelabel: null,
      //pagination code
      perpage: 10,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      query: null,
      showPerPage: false,
      ///
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(this.lastPage, this.currentPage, this.totPage);
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
  },
  methods: {
    formateDate: function (d) {
      return this.$utils.formateDate(d);
    },
    opencomment(comment) {
      var view = this;
      view.viewcomments = true;
      view.commenthtml = comment;
    },
    delete_records() {
      var view = this;
      if (view.selected.length > 0) {
        view.$swal
          .fire({
            title: "Are you sure ?",
            text: "This will remove record.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, remove it !",
            cancelButtonText: "No, cancel !",
            closeOnConfirm: false,
            closeOnCancel: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              let secid = [];
              for (var l in view.selected) {
                secid.push(this.selected[l].id);
              }
              const path = view.$url("CONTACTUS_MAIL") + "?id=" + secid.join();
              this.isLoading = true;
              this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
                .delete(path)
                .then((res) => {
                  this.isLoading = false;
                  view.selected = [];
                  view.getMobileDigital(1);
                  this.$swal.fire({
                    icon: "success",
                    title: "Contact Us Mail",
                    text: "Record has been deleted successfully",
                  });
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              view.selected = [];
            }
          });
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select Record",
        });
      }
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getMobileDigital(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getMobileDigital(this.page);
    },
    open_img_popup(imgurl) {
      var view = this;
      window.open(
        imgurl,
        "",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,,width=600,height=600"
      );
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getMobileDigital(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getMobileDigital(this.page);
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    validate() {
      this.$refs.form.validate();
    },

    getMobileDigital(page = "", query = "") {
      let check = this;
      const path = check.$url("DM_REPORT") + "?page=" + page;
      check.isLoading = true;
      console.log(path + "checking path");
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          console.log(res);
          let payload = res.data.payload;
          this.total = payload.length;
          this.totRecords = res.data.payload.totaldata;
          this.mobiledata = payload;
          // this.pageNo = res.data.lastPage;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    addToDelete(e, atchid) {
      this.checkedthis = false;
      if (e.target.checked) {
        if (this.checked_Id.indexOf(e.target.value) == -1) {
          this.checked_Id.push(e.target.value);
        }
      } else {
        this.checked_Id.splice(this.checked_Id.indexOf(e.target.value), 1);
      }
      let checkcedstr = this.checked_Id.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deleteMobileDigital(e) {
      e.preventDefault();
      let view = this;
      view.isLoading = true;
      console.log(this.selected);
      var ids_obj = { id: this.selected };
      let idarr = [];
      for (const selectitem in this.selected) {
        idarr.push(this.selected[selectitem].id);
      }

      this.$fetch({ requiresAuth: true, operation: "delete", vueScope: view })
        .delete(this.$url("MOBILE_DIGITAL"), {
          params: { id: idarr },
        })
        .then((response) => {
          view.isLoading = false;
          if (response.data.statusCode == 200 && this.message1 == null) {
            this.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted",
              text: response.data.payload.message,
            });
          }
          view.getMobileDigital();
          view.selected = [];
        })
        .catch((error) => {
          console.error(error);
          view.isLoading = false;
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: "Something went wrong!",
          });
        });
    },

    selectAll: function () {
      let mobiledata = this.mobiledata;
      if (this.allSelected == true) {
        if (Array.isArray(mobiledata) && mobiledata.length) {
          this.selected = [];
          let i;
          for (i = 0; i < mobiledata.length; i++) {
            console.log(mobiledata[i]);
            this.selected.push(mobiledata[i][0]);
          }
        }
      } else if (this.allSelected == false) {
        this.selected = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
    searchModuleName: function () {
      this.getMobileDigital(this.page, this.query);
      console.log(this.query);
    },

    mobilemassupdatedialogStatus(o) {
      var view = this;
      view.mobilemassupdatedialog = false;
      view.selected = [];
      if (o == "save") view.getMobileDigital();
    },
    mobilemassproductupdate() {
      var view = this;

      if (view.selected.length > 0) {
        view.mobilemassupdatedialog = true;
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select ProductID",
        });
      }
    },
  },
  mounted() {
    this.getMobileDigital(1);
  },
  watch: {
    "$store.state.Rawdata.current_active_module": function (o) {
      if (o) {
        this.getMobileDigital(1);
      }
    },
    //  page: function (ob) {
    //  this.getMobileDigital(ob);
    //  },
  },
};
</script>
