<template>
  <div id="MobileDigitalTemp">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      color="#007bff"
      :height="45"
      :width="75"
      :is-full-page="fullPage"
    ></loading>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Create Mobile Digital Temp Product
      </v-card-title>

      <v-card-text class="mt-5">
        <v-row v-if="companies.length > 0">
          <v-col cols="10">
            <ul class="complist" style="padding: 0px; font-size: 12px">
              <li v-for="(c, i) in companies" v-bind:key="i">
                {{ c.companyName }}

                <span
                  v-ripple="{ class: `primary--text` }"
                  @click.prevent="traverseCompanies(c, true)"
                  >Up</span
                >
                <span
                  v-ripple="{ class: `primary--text` }"
                  @click.prevent="traverseCompanies(c, false)"
                  >Down</span
                >
                <span
                  v-ripple="{ class: `primary--text` }"
                  @click.prevent="removeCompany(c)"
                  >Remove</span
                >
              </li>
            </ul>
          </v-col>
        </v-row>
        <AddCompanyTooltip
          @selectCompany="selectCompany"
          @close="closeAddCompany"
        />
        <v-row style="margin-top: -35px">
          <v-col cols="10" sm="10" md="10">
            <div v-if="sectorData.length > 0">
              <ul
                class="sectorlist"
                style="list-style: none; margin-left: -30px"
              >
                <li
                  v-for="(c, i) in sectorData"
                  v-bind:key="i"
                  style="float: none"
                >
                  <span
                    v-for="(el, index) in c"
                    v-bind:key="index"
                    style="
                      margin: 0 5px 0 5px;
                      font-size: 12px;
                      cursor: pointer;
                      color: #00a4e4;
                    "
                  >
                    <strong v-if="index == 0">{{ el.name }}</strong>
                    <strong v-else>/ {{ el.name }}</strong>
                  </span>
                  <span
                    style="
                      margin: 0 5px 0 5px;
                      font-size: 12px;
                      cursor: pointer;
                      color: #00a4e4;
                    "
                    v-ripple="{ class: `primary--text` }"
                    @click.prevent="traverseSectorCombinations(i, true)"
                  >
                    Up</span
                  >
                  <span
                    style="
                      margin: 0 5px 0 5px;
                      font-size: 12px;
                      cursor: pointer;
                      color: #00a4e4;
                    "
                    v-ripple="{ class: `primary--text` }"
                    @click.prevent="traverseSectorCombinations(i, false)"
                  >
                    Down</span
                  >

                  <span
                    style="
                      margin: 0 5px 0 5px;
                      font-size: 12px;
                      cursor: pointer;
                      color: #00a4e4;
                    "
                    v-ripple="{ class: `primary--text` }"
                    @click.prevent="removeSectorCombination(i)"
                  >
                    Remove</span
                  >
                </li>
              </ul>
            </div>
            <v-select
              v-model="selected_sector"
              v-show="showAddSectorName"
              label="Sector"
              :items="sectors"
              @change="findCateg(selected_sector)"
            ></v-select>
            <v-select
              v-if="selected_sector"
              v-model="selected_category"
              :items="secCategory"
              v-show="showAddSectorName"
              label="Category"
              @change="findSubCateg(selected_category)"
            ></v-select>
            <v-select
              v-if="selected_category"
              v-model="selected_subcategory"
              :items="secSubCategory"
              v-show="showAddSectorName"
              label="Sub Category"
            ></v-select>
            <v-btn
              small
              v-if="selected_sector"
              @click="showSectorData()"
              color="blue darken-1"
              >Add Sector</v-btn
            >
          </v-col>
        </v-row>
        <v-row style="margin-top: -25px">
          <v-col cols="10">
            <v-text-field
              sm="1"
              md="1"
              v-model="massproductName"
              label="Product Name"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="primary" class="mr-4" @click="saveMassUpdate">
          Save
        </v-btn>

        <v-btn
          small
          color="blue-grey"
          class="ma-2 white--text"
          @click="cancelmassupdate"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import AddCompanyTooltip from "./AddCompanyTooltip";
import Loading from "vue-loading-overlay";
export default {
  name: "MobileDigitalTemp",
  components: {
    Loading,
    AddCompanyTooltip,
  },
  props: ["selected"],
  mounted() {
    var view = this;
    //this is for getting sector data
    view.getCompany(1);
  },
  data: () => ({
    //expandable
    isLoading: false,
    fullPage: true,
    sectordata_ar: [],
    showPerPage: false,
    massproductName: "",
    companies: [],
    openAddCompany: false,
    addSectorId: [],
    allSectorIds: [],
    all_sectors: [],
    sectors: [],
    secCategory: [],
    secSubCategory: [],
    subcatName: [],
    subcategories: [],
    showCategory: false,
    showSubCategory: false,
    showSubSubCategory: false,
    showAddSectorName: true,
    all_category: [],
    all_sub_category: [],
    all_sub_sub_category: [],
    selected_sector: "",
    selected_category: "",
    selected_subcategory: "",
    sectorData: [],
    sectors: [],
    dmail_product_name: "",
    dmail_product_companies: [1],
    dmail_product_sectors: [2],
  }),
  methods: {
    //pagination methods
    closeAddCompany: function (o) {
      this.openAddCompany = o;
    },
    selectCompany: function (c) {
      var view = this;
      console.log(c);
      let obj = {};
      obj["companyID"] = c.companyID;
      obj["companyName"] = c.companyName;
      const found = view.companies.some((el) => el.companyID === c.companyID);
      if (found == false) view.companies.push(obj);
      else
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Company has been already added",
        });
    },

    traverseCompanies: function (o, order) {
      let view = this;
      let newcompanies = view.companies,
        index = _.findIndex(newcompanies, function (i) {
          return i.companyID === o.companyID;
        }),
        toObjOrder =
          order === true
            ? parseInt(index) - parseInt(1)
            : parseInt(index) + parseInt(1),
        thisObj = o,
        thisObjOrder = index,
        toObj = newcompanies[toObjOrder];

      if (
        typeof newcompanies[thisObjOrder] === "undefined" ||
        typeof newcompanies[toObjOrder] === "undefined"
      ) {
        return;
      }

      let tempobj = "";
      tempobj = newcompanies[index];
      newcompanies[index] = newcompanies[toObjOrder];
      newcompanies[toObjOrder] = tempobj;
      view.companies = [];
      view.companies = newcompanies;
    },
    removeCompany: function (i) {
      let view = this;
      let newCompanies = view.companies.filter(
        (o) => o.companyID !== i.companyID
      );
      view.companies = newCompanies;
    },
    updateSubmittableSectors: function (payload) {
      let view = this;
      if (Array.isArray(payload) && payload.length) {
        view.sectorData;
        let sectorsSubmittablePayload = [];
        payload.forEach((item) => {
          let newCombination = [];
          item.forEach((o) => {
            if (o.id) {
              newCombination.push(o.id);
            }
          });
          sectorsSubmittablePayload.push(newCombination);
        });
        view.sectorData;
      } else {
        view.sectorData;
        view.sectorData;
      }
    },
    getCompany(page = "", query = "") {
      let check = this;
      const path = check.$url("COMPANY") + "?q=" + query + "&page=" + page;
      check.isLoading = true;
      check
        .$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(path)
        .then((res) => {
          check.isLoading = false;

          this.all_sectors = res.data.payload.company[3];
          if (res.data.payload.company[3].length) {
            res.data.payload.company[3].forEach(function (item) {
              let a = {
                text: item.name,
                value: item.id,
              };
              check.sectors.push(a);
              check.allSectorIds.push(a);
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showSectorData() {
      let selectedCombination = [];
      let check = this;
      selectedCombination.push(this.selected_sector);
      selectedCombination.push(this.selected_category);
      selectedCombination.push(this.selected_subcategory);
      var filtered = selectedCombination.filter(function (el) {
        return el != "";
      });

      let combination = [];
      if (!this.sectordata_ar.includes(filtered.join())) {
        this.sectordata_ar.push(filtered.join());
        this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
          .post(this.$url("COMPANYSEC"), { selectedIds: filtered })
          .then((res) => {
            // this.sectorData = [];
            console.log(res.data.payload[0]);
            let allSectors = res.data.payload[0];
            allSectors.forEach(function (o, j) {
              combination.push({ id: o.id, name: o.name });
            });

            this.sectorData.push(combination);
          });
      } else {
        this.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Sectors/Category Combination already added",
        });
      }

      this.selected_sector = [];
      this.selected_category = [];
      this.selected_subcategory = [];
      this.secSubCategory = [];
    },
    findCateg(atchid) {
      let view = this;
      view.addSectorId.push(atchid);
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(view.$url("GETSECTORS"), { params: { id: atchid } })
        .then((res) => {
          view.secCategory = [];
          // console.log(res.data.payload);
          if (res.data.payload.length) {
            let filtered = view.filter_allowed_sectors(res.data.payload);
            view.secCategory = [];
            view.secCategory = filtered;
          }
        });
    },
    findSubCateg(atchid) {
      let view = this;
      view.addSectorId.push(atchid);
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(view.$url("GETSECTORS"), { params: { id: atchid } })
        .then((res) => {
          view.secSubCategory = [];
          console.log(res.data.payload);
          if (res.data.payload.length) {
            let filtered = view.filter_allowed_sectors(res.data.payload);
            view.secSubCategory = [];
            view.secSubCategory = filtered;
          }
        });
    },
    filter_allowed_sectors: function (response) {
      let allowed_sectors = this.$store.getters["Rawdata/getSectors"];
      let filtered = [];
      if (response.length) {
        response.forEach((o) => {
          if (allowed_sectors.includes(o.value)) {
            filtered.push(o);
          }
        });
      }
      return filtered;
    },
    traverseSectorCombinations: function (i, order) {
      var sectorsData = this.sectorData;
      let toObjOrder = "";
      let thisObj = "";
      let thisObjOrder = "";
      let toObj = "";
      (toObjOrder =
        order === true ? parseInt(i) - parseInt(1) : parseInt(i) + parseInt(1)),
        (thisObj = sectorsData[i]),
        (thisObjOrder = i),
        (toObj = sectorsData[toObjOrder]);

      if (
        typeof sectorsData[thisObjOrder] === "undefined" ||
        typeof sectorsData[toObjOrder] === "undefined"
      ) {
        return;
      }
      sectorsData[thisObjOrder] = toObj;
      sectorsData[toObjOrder] = thisObj;
      console.log(sectorsData);
      this.sectorData = [];
      this.sectorData = sectorsData;
      // this.updateSubmittableSectors(sectorsData);
    },
    removeSectorCombination: function (i) {
      let view = this,
        sectorsData = view.sectorData;
      view.sectordata_ar = [];
      if (sectorsData.length == 0) {
        sectorData = [];
        view.updateSubmittableSectors(sectorsData);
      } else {
        if (i > -1) {
          sectorsData.splice(i, 1);
          //console.log(i);
        }
        view.updateSubmittableSectors(sectorsData);
      }
    },
    saveMassUpdate: function () {
      var view = this;
      view.isLoading = true;
      const itemobj = view.selected;
      var idarr = [];
      var channelarr = [];
      var compidarr = [];
      var sectordataarr = [];
      var es_index_ar = [];
      view.isLoading = true;

      for (const es in itemobj) {
        es_index_ar.push(itemobj[es].index_name);
      }

      for (const item in itemobj) {
        idarr.push(itemobj[item].creative_id);
      }
      for (const item in itemobj) {
        channelarr.push(itemobj[item].digital_channel);
      }
      for (const comp in view.companies) {
        compidarr.push(view.companies[comp].companyID);
      }

      var productdata_obj = {
        product_name: view.massproductName,
        id: idarr,
        digital_channel: channelarr,
        sectors: view.sectordata_ar,
        companies: compidarr,
        user_id: view.$utils.getCookies("user_id"),
        index_name: es_index_ar,
      };

      console.log("mass update");
      console.log(productdata_obj);
      // return false
      let productsave_api = view.$url("MOBILE_TEMP_PRODUCT");
      this.$fetch({ requiresAuth: true })
        .post(productsave_api, productdata_obj)
        .then((response) => {
          view.massproductName = "";
          idarr = [];
          view.selected = [];
          view.companies = [];
          view.sectorData = [];
          view.selected_sector = "";
          view.selected_category = "";
          view.selected_subcategory = "";
          view.sectordata_ar = [];

          view.isLoading = false;

          this.$swal.fire({
            icon: "success",
            title: "Product Capture",
            text: "Temp Product Captured succesfully",
          });
          this.$emit("mobilemassupdatedialogStatus", "save");
        })
        .catch(function (response) {
          if (response.response.status == 401) {
            window.location.href;
          }
        });
    },
    cancelmassupdate: function () {
      var view = this;
      view.massproductName = "";
      view.deleteItems = [];
      view.companies = [];
      view.sectorData = [];
      view.sectordata_ar = [];
      view.selected_sector = "";
      view.selected_category = "";
      view.selected_subcategory = "";
      view.massupdatedialog = false;
      this.$emit("mobilemassupdatedialogStatus", "cancel");
    },
  },
};
</script>


